const Colors = {
  PRIMARY: '#40D78F',
  RED: '#FF5764',
  ORANGE: '#FFA134',
  LIGHTBLUE: '#35B1FF',
  PURPLE: '#6D78FF',
  GREEN: '#40D78F',
  YELLOW: '#FFE34F',
  PINK: '#FF4D97',
  BLUE: "#1035F1",
  WHITE: "#FFFFFF",
  
  TEXT: '#333333',
  TEXT_HALF_OPACITY: '#33333380',
  SEPARATOR: '#F1F1F1',
  GRAY: '#CFCFCF',
  PLACEHOLDERS: '#BBBBBB',
  ACHIEVEMENTS_GRAY: '#ABABAB',
  CAPTION_GRAY: '#686868',
  BACKGROUND_GRAY: '#626262',
  LIGHT_GRAY: '#E9E9E9',
  BLACK: '#373737',
  
  DARKER_BACKGROUND: '#F5F5F6',
  DARKER_PURPLE: '#535CCF',
  DARKER_ORANGE: '#E1861C',
  DARKER_LIGHTBLUE: '#008AE0',
  DARKER_PINK: '#DD2B75',
  ICON_BACKGROUND_VIOLET: '#C0C5FF',
  ICON_BACKGROUND_ORANGE: '#FFDDB6',
  ICON_BACKGROUND_GREEN: '#bcffc0',
  ICON_BACKGROUND_RED: '#FFBCC1',
  ICON_BACKGROUND_LIGHTBLUE: '#ADE0FF',
  ICON_BACKGROUND_PINK: '#FF7FCA',

  GENIALLY_WHITE: "#FAF4EC",
}

export default Colors;